<nb-card class="border-radius-1 white-fill padding-1">
  <ng-container *ngIf="error">
    <div class="header-1 flex-x column-gap-1">
      <nb-icon class="margin-block-auto" icon="close-outline" status="danger"></nb-icon>
      {{ errorMsgs.length > 0 ? 'Ingestion Error' : 'Unknown Error' }}
    </div>
    <nb-card-body>
      <div>An Error occurred while ingesting this ontology.</div>
      <div *ngFor="let errorMsg of errorMsgs" class="margin-block-1">
        <b>{{ errorMsg }}</b>
      </div>
      <div>Please try again later.</div>
      <div>If this persists, please contact support.</div>
    </nb-card-body>
    <div class="margin-inline-auto width-fit">
      <button nbButton outline status="primary" (click)="close()" shape="round">Dismiss</button>
    </div>
  </ng-container>

  <ng-container *ngIf="!error">
    <div *ngIf="!loading">
      <div class="header-1">Upload new {{ ontology.ontology_name }} ontology</div>
      <nb-card-body>
        <div class="text-center">
            <div class="margin-bottom-1 header-2">Select <b>.owl</b>/<b>.ttl</b>/<b>.obo</b> file</div>
          <app-file-upload (fileSelected)="onFileSelected($event)" fileFormat=".owl, .ttl, .obo"></app-file-upload>
          <div class="text-small text-center margin-block-1">{{ file ? file.name + ' selected ✅' : '' }}</div>
        </div>
      </nb-card-body>
      <div class="flex-x column-gap-2 margin-inline-auto width-fit">
        <button nbButton outline status="primary" (click)="close(false)" shape="round">Cancel</button>
        <button nbButton status="primary" (click)="uploadNewOwlFile()" shape="round" [disabled]="file === undefined">
          Upload
        </button>
      </div>
    </div>

    <div *ngIf="loading">
      <div class="header-1">Ingesting {{ file ? file.name : '' }} ...</div>
      <div class="margin-block-1">
        Target Ontology: <b>{{ ontology.ontology_name }}</b>
      </div>
      <div *ngIf="estimatedRunTime">
        Estimated ingestion time:
        <b>{{ estimatedRunTime * 1000 | date: 'm' }}m {{ estimatedRunTime * 1000 | date: 's' }}s</b>.
      </div>
      <nb-card-body>
        <div class="grid-2-columns column-gap-1 row-gap-1 margin-bottom-1">
          <ng-container *ngIf="!fileUploaded">
            <div [nbSpinner]="true" nbSpinnerStatus="primary" class="margin-inline-auto margin-block-auto">
              <nb-icon icon="cloud-upload-outline" status="primary"></nb-icon>
            </div>
            Uploading Ontology file ...
          </ng-container>
          <ng-container *ngIf="fileUploaded">
            <div class="margin-inline-auto margin-block-auto">
              <nb-icon icon="checkmark-circle-2-outline" status="success"></nb-icon>
            </div>
            Uploaded Ontology file
          </ng-container>

          <ng-container *ngIf="!fuzzyMapReady">
            <div [nbSpinner]="true" nbSpinnerStatus="primary" class="margin-inline-auto margin-block-auto">
              <nb-icon icon="cloud-upload-outline" status="primary"></nb-icon>
            </div>
            Generating Fuzzy mapping ...
          </ng-container>
          <ng-container *ngIf="fuzzyMapReady">
            <div class="margin-inline-auto margin-block-auto">
              <nb-icon icon="checkmark-circle-2-outline" status="success"></nb-icon>
            </div>
            Fuzzy mapping Ready
          </ng-container>

          <ng-container *ngIf="!aiMapReady">
            <div [nbSpinner]="true" nbSpinnerStatus="primary" class="margin-inline-auto margin-block-auto">
              <nb-icon icon="cloud-upload-outline" status="primary"></nb-icon>
            </div>
            Generating AI mapping ...
          </ng-container>
          <ng-container *ngIf="aiMapReady">
            <div class="margin-inline-auto margin-block-auto">
              <nb-icon icon="checkmark-circle-2-outline" status="success"></nb-icon>
            </div>
            AI mapping ready
          </ng-container>
        </div>
      </nb-card-body>
      <div class="margin-inline-auto width-fit">
        <button nbButton outline status="primary" (click)="close()" shape="round">Close</button>
      </div>
    </div>
  </ng-container>
</nb-card>
