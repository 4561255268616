<div>
  <igx-grid
    #grid
    [data]="taskList ? taskList.tasks : []"
    [isLoading]="loading"
    [emptyGridMessage]="error ? '❌ Error: ' + error : 'Grid has no data.'"
    [autoGenerate]="false"
    [height]="null"
    [allowFiltering]="true"
    [primaryKey]="'id'"
    (cellEdit)="valueUpdated($event)">
    <igx-grid-toolbar>
      <igx-grid-toolbar-title><b>Previous Mapping Tasks</b></igx-grid-toolbar-title>
    </igx-grid-toolbar>
    <igx-column field="label" header="Job Name ✏️" [resizable]="true" [editable]="true"></igx-column>
    <igx-column field="terms_input_type" header="Input Type" [resizable]="true" [sortable]="true">
      <ng-template igxCell let-val>
        <ng-container *ngIf="val === 'individual'">Individual Terms</ng-container>
        <ng-container *ngIf="val === 'filecolumns'">File columns</ng-container>
        <ng-container *ngIf="val === 'ontologyToOntology'">Ontology</ng-container>
      </ng-template>
    </igx-column>
    <igx-column field="task_name" header="Mapping Type" [resizable]="true" [sortable]="true">
      <ng-template igxCell let-val>
        {{ mappingService.getMappingMethodology(val).label | uppercase }}
      </ng-template>
    </igx-column>
    <igx-column field="ontologies" header="Ontologies" [resizable]="true">
      <ng-template igxCell let-val>{{ val?.join(', ') }}</ng-template>
    </igx-column>
    <igx-column field="submitted_at" header="Date Submitted" dataType="dateTime" [resizable]="true" [sortable]="true">
    </igx-column>
    <igx-column
      field="total_terms"
      header="# of Terms"
      dataType="number"
      [resizable]="true"
      [sortable]="true"></igx-column>
    <igx-column field="status" header="Status" [resizable]="true" [sortable]="true">
      <ng-template igxCell let-val let-cell="cell">
        <div class="flex-x">
          <nb-icon
            [icon]="
              val.toUpperCase() === 'COMPLETED'
                ? 'checkmark-square-2'
                : val.toUpperCase() === 'FAILED' || val.toUpperCase() === 'CANCELLED'
                ? 'close-square'
                : 'minus-square'
            "
            [status]="
              val.toUpperCase() === 'COMPLETED'
                ? 'success'
                : val.toUpperCase() === 'FAILED' || val.toUpperCase() === 'CANCELLED'
                ? 'danger'
                : 'info'
            ">
          </nb-icon>
          <div class="text-center width-fit">&nbsp;{{ val | titlecase }}</div>
          <div
            *ngIf="cell.row.data.status.toUpperCase() === 'IN-PROGRESS' && cell.row.data.progress !== undefined"
            class="text-center width-fit">
            &nbsp;({{ cell.row.data.progress | number: '1.0-0' }}%)
          </div>
        </div>
      </ng-template>
    </igx-column>
    <igx-column field="result_download" header="Actions" [resizable]="true" [filterable]="false">
      <ng-template igxCell let-cell="cell">
        <div class="flex-x column-gap-1">
          <button
            nbButton
            status="primary"
            outline
            shape="round"
            nbTooltip="Export"
            nbTooltipPlacement="bottom"
            *ngIf="cell.row.data.status === 'COMPLETED'"
            (click)="download(cell.row.data)">
            <nb-icon nbButton icon="download" [options]="{ animation: { type: 'pulse' } }"> </nb-icon>
          </button>
          <button
            *ngIf="cell.row.data.task_request_url"
            class="margin-block-auto"
            nbButton
            status="primary"
            shape="round"
            nbTooltip="Re-Run"
            nbTooltipPlacement="bottom"
            outline
            [routerLink]="['/new-mapping', cell.row.data.task_request_url]">
            <nb-icon nbButton icon="repeat" [options]="{ animation: { type: 'pulse' } }"> </nb-icon>
          </button>
        </div>
      </ng-template>
    </igx-column>
    <igx-paginator></igx-paginator>
  </igx-grid>
</div>
