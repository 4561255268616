import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbMenuItem, NbMenuService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { AddCustomOntologyDialogComponent } from 'src/app/components/add-custom-ontology-dialog/add-custom-ontology-dialog.component';
import { DeleteOntologyDialogComponent } from 'src/app/components/delete-ontology-dialog/delete-ontology-dialog.component';
import { UploadOntologyOwlFileDialogComponent } from 'src/app/components/upload-ontology-owl-file-dialog/upload-ontology-owl-file-dialog.component';
import { IOntology, IOntologyAvailableData } from 'src/app/interfaces/i-ontology';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { KeycloakService } from 'src/app/services/keycloak.service';
import { OntologyService } from 'src/app/services/ontology.service';

@Component({
  selector: 'app-manage-ontologies',
  templateUrl: './manage-ontologies.component.html',
  styleUrls: ['./manage-ontologies.component.scss'],
})
export class ManageOntologiesComponent implements OnInit, OnDestroy {
  public ontologies: IOntology[] | undefined;
  public loading = true;
  public error: string | undefined;

  private editLabel = 'Edit';
  private mappingRulesLabel = 'Mapping Rules';
  private uploadOwlLabel = 'Upload Ontology file';
  private deleteLabel = 'Delete';
  public contextMenuItems: NbMenuItem[] = [
    { title: this.editLabel, icon: 'edit-2-outline' },
    { title: this.uploadOwlLabel, icon: 'file-text-outline' },
    { title: this.deleteLabel, icon: 'trash-2-outline' },
  ];
  private subscriptions: Subscription[] = [];
  constructor(
    public ontologyService: OntologyService,
    private dialogService: NbDialogService,
    public keycloakService: KeycloakService,
    private nbMenuService: NbMenuService,
    private router: Router,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.handleOntologyServiceStatus();
    this.handleSettingsClick();
  }

  private handleOntologyServiceStatus() {
    const sub1 = this.ontologyService.initialized$.subscribe({
      next: initialized => {
        if (initialized) {
          setTimeout(() => {
            this.ontologies = this.ontologyService.allOntologies;
            this.ontologies.map(o => (o.methodsList = this.getMethods(o.available_data)));
            this.loading = false;
          }, 250);
        }
      },
      error: err => {
        this.loading = false;
        if (typeof err === 'string') {
          this.error = err;
        } else {
          this.error = 'Error: ' + err.status + ' - ' + err.statusText;
        }
      },
    });
    this.subscriptions.push(sub1);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private loadOntologies() {
    this.loading = true;
    this.ontologyService.fetchOntologies();
  }

  private handleSettingsClick() {
    const sub2 = this.nbMenuService.onItemClick().subscribe(menuItem => {
      const clickedLabel = menuItem?.item?.title;
      const ontology: IOntology = menuItem.tag as unknown as IOntology;
      switch (clickedLabel) {
        case this.editLabel: {
          this.update(ontology);
          this.analytics.trackEvent(
            'ontology_actions_update_clicked',
            'User Clicked update from the Ontology Actions column',
            'ontology'
          );
          break;
        }
        case this.mappingRulesLabel: {
          this.router.navigate(['manage-ontologies/' + ontology.ontology_id + '/mapping-rules']);
          this.analytics.trackEvent(
            'ontology_actions_view_mapping_rules_clicked',
            'User Clicked view mapping rules from the Ontology Actions column',
            'ontology'
          );
          break;
        }
        case this.deleteLabel: {
          this.delete(ontology);
          this.analytics.trackEvent(
            'ontology_actions_delete_clicked',
            'User Clicked Delete Ontology from the Ontology Actions column',
            'ontology'
          );
          break;
        }
        case this.uploadOwlLabel: {
          this.uploadOwlFile(ontology);
          this.analytics.trackEvent(
            'ontology_actions_upload_owl_file_clicked',
            'User Clicked Upload Owl file from the Ontology Actions column',
            'ontology'
          );
          break;
        }
      }
    });
    this.subscriptions.push(sub2);
  }

  delete(ontology: IOntology) {
    this.dialogService
      .open(DeleteOntologyDialogComponent, {
        context: {
          ontology,
        },
      })
      .onClose.subscribe({
        next: result => {
          if (result?.updated === true) {
            this.loadOntologies();
            this.analytics.trackEvent(
              'ontology_delete_success',
              'User Deleted an ontology from the Ontologies Page',
              'ontology'
            );
          }
        },
      });
  }

  update(ontology: IOntology) {
    this.dialogService
      .open(AddCustomOntologyDialogComponent, {
        context: {
          ontology,
        },
      })
      .onClose.subscribe({
        next: result => {
          if (result?.updated === true) {
            this.loadOntologies();
            this.analytics.trackEvent(
              'ontology_update_success',
              'User Updated an ontology from the Ontologies Page',
              'ontology'
            );
          }
        },
      });
  }

  uploadOwlFile(ontology: IOntology) {
    this.dialogService
      .open(UploadOntologyOwlFileDialogComponent, {
        context: {
          ontology,
        },
      })
      .onClose.subscribe({
        next: result => {
          if (result?.updated === false) {
            // Do not update ontology list
          } else {
            this.loadOntologies();
            this.analytics.trackEvent(
              'ontology_upload_owl_file_success',
              'User Deleted an ontology from the Ontologies Page',
              'ontology'
            );
          }
        },
      });
  }

  private getMethods(methods: IOntologyAvailableData[]): string {
    const ingestionInProgress = methods.filter(m => m.data_status === 'Loading in progress');
    const ingestionInProgress2 = methods.filter(m => m.last_ingestion?.status === 'IN-PROGRESS');
    if (ingestionInProgress.length > 0 || ingestionInProgress2.length > 0) {
      return 'Ingesting ...';
    }
    const methodError = methods.filter(m => m.data_status.toUpperCase().includes('FAILED'));
    const methodError2 = methods.filter(m => m.last_ingestion?.status.toUpperCase().includes('FAILED'));
    if (methodError.length > 0 || methodError2.length > 0) {
      return 'Last Ingestion FAILED';
    }
    const availableData = methods.filter(m => m.data_status === 'Available');
    if (availableData.length > 0) {
      const availableTypesArray = availableData.map(m => m.data_type);
      return availableTypesArray.sort().join(', ').toUpperCase();
    } else {
      return 'NONE. Please upload OWL file';
    }
  }

  addOntology() {
    this.analytics.trackEvent(
      'ontology_add_new_clicked',
      'User Clicked Add new ontology from the Ontologies Page',
      'ontology'
    );
    this.dialogService.open(AddCustomOntologyDialogComponent).onClose.subscribe({
      next: result => {
        if (result?.updated === true) {
          this.loadOntologies();
          this.analytics.trackEvent(
            'ontology_add_new_success',
            'User Added a new ontology from the Ontologies Page',
            'ontology'
          );
        }
      },
    });
  }
}
